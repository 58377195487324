body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*html,
body,
#root,
#root > div {
  height: 100%;
}*/

/*html {
  height: 100%;
}
body {
  min-height: 100%;
}*/

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  background-color: #f0f0f0;
}

/*::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #213649;
}

::-webkit-scrollbar-thumb {
  background: #4094da;
}

::-webkit-scrollbar-thumb:hover {
  background: #216caa;
}*/

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
